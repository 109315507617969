import GatsbyLink from 'gatsby-link'
import React, { FC } from 'react'
import styled from 'styled-components'
import Navigation from '../components/navigation'

import SEO from '../components/seo'

const HeroSection = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${p => p.theme.colors.yves};
	color: ${p => p.theme.colors.silver};
`

const Content = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding-left: 2rem;
	padding-right: 2rem;

	display: flex;
	flex-flow: column;
`

const NameRow = styled.div<{ align?: 'start' | 'end' }>`
	position: relative;
	display: flex;

	justify-content: ${p => (p.align === 'end' ? 'flex-end' : 'flex-start')};
	padding-top: 4rem;
	padding-bottom: 2rem;

	flex: 0 0 auto;

	font-size: 4rem;
	text-transform: uppercase;
`

const ContentRow = styled.div`
	position: relative;
	display: flex;

	flex: 1 1 0;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 2rem;
	text-align: center;

	p {
		margin: 0 0 0.2rem 0;
	}
`

const StyledLink = styled.a`
	display: inline-flex;
	border: 1px solid ${p => p.theme.colors.silver};
	text-decoration: none;
	margin-top: 1rem;
	padding: 1rem;

	color: ${p => p.theme.colors.silver};
	font-style: italic;

	:hover {
		color: ${p => p.theme.colors.yves};
		background: ${p => p.theme.colors.silver};
	}
`

const TextualGatsbyLink = styled(GatsbyLink)`
	display: block;
	color: ${p => p.theme.colors.silver};

	cursor: pointer;
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}

	&::visited {
		color: ${p => p.theme.colors.silver};
	}
`

const TopNav = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	width: 100%;
`

const IndexPage: FC<{}> = () => (
	<>
		<SEO />
		<HeroSection>
			<TopNav>
				<Navigation />
			</TopNav>
			<Content>
				<NameRow>Laura</NameRow>
				<ContentRow>
					<div>
						<p>Castelul Károlyi</p>
						<p>Carei, Romania</p>
						<p>15 Octombrie 2022</p>
						<StyledLink href="https://hwzzd9bd2yu.typeform.com/to/RlEzTudq">
							RSVP
						</StyledLink>
					</div>
				</ContentRow>
				<NameRow align="end">Emil</NameRow>
			</Content>
		</HeroSection>
	</>
)

export default IndexPage
